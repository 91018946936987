<template>
    <div class='index'>
      <router-view :did="did"/>
    </div>
  </template>
<script>
export default {
    props: {
        did: {
            type: [String, Number]
        }
    },
    data(){
        return {
    
        }
    },
    mounted(){
    },
    methods: {

    
    }
}
</script>



<style lang="scss" scoped>
.TeamTestList {

    .el-pagination,
    .clearfix {
        text-align: left;
    }

    .delColor {
        color: red;

    }

}
</style>